import styled from 'styled-components'
import shouldForwardProp from '@styled-system/should-forward-prop'
import { Text } from './Primitives'

/**
 * TextWithMargin component.
 *
 * This component extends the `Text`, adding a top margin to the next sibling div.
 *
 * @component
 * @example
 * // Usage example:
 * <TextWithMargin>
 *   Some text
 * </TextWithMargin>
 * <div>Next sibling div with top margin</div>
 *
 * @param {Object} props - The props that are passed to the component.
 * @returns {JSX.Element} The TextWithMargin component.
 */
export const TextWithMargin = styled(Text).withConfig({ shouldForwardProp })`
  + div {
    margin-top: 1em;
  }
`
