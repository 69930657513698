import React from 'react'
import styled from 'styled-components'

const Review = styled.div`
  max-width: 600px;
  padding: 0 2em;
  margin: 0 auto;
`

const ReviewInner = styled.div`
  position: relative;
  font-style: italic;
  border-left: none;
  line-height: 1.5;
  color: #757575;
  &::before {
    content: '〞';
    position: absolute;
    color: #ddd;
    font-size: 80px;
    left: -0.9em;
    top: -1em;
    text-align: right;
    line-height: 1.3;
    transform: rotate(180deg);
  }

  @media screen and (max-width: 640px) {
    &::before {
      font-size: 60px;
    }
  }
`

const ByUser = styled.div`
  font-size: 15px;
  color: #757575;
`

export default ({ by, app, ...props }) => (
  <Review>
    <ReviewInner {...props} />
    <ByUser>- {by}</ByUser>
  </Review>
)
