import styled from 'styled-components'
import { typography } from 'styled-system'
import shouldForwardProp from '@styled-system/should-forward-prop'
import { Text } from './Primitives'

/**
 * Bold component.
 *
 * This component renders a bold text element.
 *
 * @component
 * @example
 * // Usage example:
 * <Bold fontSize={2}>This is bold text</Bold>
 *
 * @param {Object} props - The props that are passed to the component.
 * @returns {JSX.Element} The Bold component.
 */
export const Bold = styled(Text)
  .withConfig({ shouldForwardProp })
  .attrs((props) => ({
    as: 'span',
    fontSize: 1,
    ...props,
  }))(
  {
    fontWeight: 'bold',
  },
  typography,
)
