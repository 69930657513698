import React from 'react'

const Vimeo = ({
  code,
  autoplay = false,
  loop = true,
  width = 500,
  aspect = 1
}) => {
  const dnt =
    typeof window !== 'undefined' &&
    window.navigator &&
    window.navigator.doNotTrack === '1'
  return (
    <div
      style={{
        maxWidth: `${width}px`,
        marginTop: '1.5em',
        marginBottom: '1.5em',
        marginLeft: 'auto',
        marginRight: 'auto'
      }}
    >
      <div style={{ padding: `${aspect * 100}% 0 0 0`, position: 'relative' }}>
        <iframe
          src={`https://player.vimeo.com/video/${code}?autoplay=${
            autoplay ? '1' : '0'
          }&muted=1&loop=${loop ? '1' : '0'}&title=0&byline=0&portrait=0&dnt=${
            dnt ? 1 : 0
          }`}
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%'
          }}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        />
      </div>
    </div>
  )
}

export default Vimeo
