import shouldForwardProp from '@styled-system/should-forward-prop'
import React from 'react'
import styled, { keyframes } from 'styled-components'
import { space } from 'styled-system'

// import LoadingBackground from './loadingbg.png'

/**
 * animateAlpha keyframe mixin for styled-components.
 *
 * This keyframe mixin fades an element in and out.
 */
export const animateAlpha = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
`

const Image = styled.img.withConfig({ shouldForwardProp })`
  animation: ${animateAlpha} 0.8s ease-in alternate infinite;
  max-width: 100%;
  ${space};
`

/**
 * LoadingAnimation component.
 *
 * This component renders a loading animation that oscillates its opacity between 1 and 0.5.
 *
 * @component
 * @example
 * // Usage example:
 * <LoadingAnimation />
 *
 * @returns {JSX.Element} The styled image component.
 */
export const LoadingAnimation = () => <Image src={BASE_64} mt={3} />

const BASE_64 =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABkUAAASLAgMAAABW+1IuAAAACVBMVEX////19/nw8PAmsZTmAAAEMUlEQVR4Ae3dvXGkQBDH0YYclI+CwFCIMgiCfMhBnLE1xp32X9el0Xe958DdDLOr+tn0FhEAAAAAAAAAAAAAAAAAAAAAAAAAy7hu9T7Oo6awFpIgiSRIIgmSSIIkSCIJkkiCJJIgiSRIgiSSIIkkSCIJkiCJJEgiCZJIgiSSIAmSSIIkkiAJAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADAfyx1sz4XX+jlqYa1+B5+eRJJAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAWMZ1q5Zrb2xvHUGwFj8gCZIgiSRIIgmSSIIkkiAJkkiCJJIgiSRIgiSSIIkkAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAy7hudc+1h6WwcB5VD4+NDQ35kLwtPNY4LuwdN+FvCP8VzvNL1n5cHEkkQRJJkARJJEESSZBEEiRBEgAAAAAAAAAAAAAAAAAAwATUaIz5DLNEx+o/t3f/PTuXNDwWTgrfoLklnHztt93h+bE/f5b33o0iQBJJkEQSJEESSZBEEiSRBEkkAQAAAAAAAAAAAAAAAAAAMAG1PaE0D0SdOTWflpfyR81PRc3L+cPS0nl4790oAiSRBEkkQRIkkQRJJEESSZBEEgAAAAAAAAAAAAAAAAAAABNQ74/5jEM+06Zw4Lh57yGk7e8wMSp1YlSs996NIkASSZBEEiRBEkmQRBIkkQRJJAEAAAAAAAAAAAAAAAAAADABtTHec2opP/KGvefhvXckkQRJkEQSJJEESSRBEkmQBEkkAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPjbUjfrc/GFXp5qWItvRhJJAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAfqdlXLdqufbW9sYRBGshCZJIgiQ/gCSSIIkkSCIJkiCJJEgiCZJIgiRIIgmSSAIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAALCM61b3XHtYCgvnUfXw2NjQkA/J2/Jj/ePGXr9kjSQfSRJJkEQSJEESSZBEEiSRBEkkAQAAAAAAAAAAAAAAAAAAMAE1Ty8ds0TD6rh9tdQfc9rYO25bJ+Vv0Nty7d57N4oASZBEEiSRBEkkQRIkkQRJJEESAAAAAAAAAAAAAAAAAACAuQmoExNK80DUiVPzaWGp/1H9qajee0eSDyGJJEgiCZIgiSRIIgmSSIIkkgAAAAAAAAAAAAAAAAAAAJiAmgeF5uGlYVM6cNxkYVNjufcdXu333juSfDJJJEESSZAESSRBEkmQRBIkkQQAAAAAAAAAAAAAAAAAAMAE1DxNdGIpP/KGvefhvfcWJJEESSRBEiSRBEkkQRJJkEQSJAEAAAAAAAAAAAAAAAAAAIA/yJLp8owplPoAAAAASUVORK5CYII='
