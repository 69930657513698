import styled from 'styled-components'
import { typography, color, space } from 'styled-system'
import shouldForwardProp from '@styled-system/should-forward-prop'

/**
 * BaseHeading component.
 *
 * This component renders a base heading element with default styles.
 *
 * @component
 * @example
 * // Usage example:
 * <BaseHeading>Base Heading</BaseHeading>
 *
 * @param {Object} props - The props that are passed to the component.
 * @returns {JSX.Element} The BaseHeading component.
 */
export const BaseHeading = styled.h1.attrs((props) => ({
  ...props,
  fontSize: props.fontSize || 4,
  fontFamily: props.fontFamily || 'system-ui, sans-serif',
  fontWeight: props.fontWeight || 700,
  lineHeight: props.lineHeight || 1.25,
}))({})

/**
 * Heading component.
 *
 * This component renders a styled heading element with additional styles and attributes.
 *
 * @component
 * @example
 * // Usage example:
 * <Heading fontSize={5} color="primary">Styled Heading</Heading>
 *
 * @param {Object} props - The props that are passed to the component.
 * @returns {JSX.Element} The Heading component.
 */
export const Heading = styled(BaseHeading)
  .withConfig({ shouldForwardProp })
  .attrs((props) => ({
    ...props,
    fontSize: props.fontSize || 3,
    lineHeight: props.lineHeight || 1,
    color: props.color || 'grayheading',
  }))(
  {
    fontFamily: 'inherit',
    textTransform: 'uppercase',
    letterSpacing: 2,
  },
  typography,
  color,
  space,
)

Heading.displayName = 'Heading'
