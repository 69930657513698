import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import theme from './Theme/theme'
import shouldForwardProp from '@styled-system/should-forward-prop'
import { color } from 'styled-system'

const StyledInput = styled('input')
  .withConfig({
    shouldForwardProp,
  })
  .attrs((props) => ({
    type: 'text',
    display: 'block',
    width: '100%',
    p: 2,
    appearance: 'none',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    border: '1px solid',
    borderRadius: 'default',
    color: 'inherit',
    bg: 'transparent',
    ...props,
  }))`
  ${color}
  padding: 20px 25px;
  border: 1px solid ${theme.colors.inputborder};
  box-shadow: none;
  border-radius: ${theme.radii[2]}px;

  &:focus {
    background-color: ${theme.colors.inputfocusbc};
    box-shadow: none;
  }
`

/**
 * TextInput component.
 *
 * This component renders a styled input field. If the `isInvalid` and `showValidation` props are both true,
 * it applies a background color indicating invalid input.
 *
 * @component
 * @example
 * // Usage example:
 * <TextInput isInvalid={true} showValidation={true} placeholder="Enter text" />
 *
 * @param {Object} props - The props that are passed to the component.
 * @param {boolean} [props.isInvalid=false] - Determines whether the input is invalid.
 * @param {boolean} [props.showValidation=false] - Determines whether to show validation styling.
 * @param {Object} rest - Additional props to pass to the input element.
 * @returns {JSX.Element} The TextInput component.
 */
export const TextInput = ({ isInvalid = false, showValidation = false, ...rest }) => {
  return isInvalid && showValidation ? (
    <StyledInput bg={theme.colors.inputinvalidbc} {...rest} />
  ) : (
    <StyledInput {...rest} />
  )
}

TextInput.propTypes = {
  isInvalid: PropTypes.bool,
  showValidation: PropTypes.bool,
}
