import { graphql, useStaticQuery } from 'gatsby'

export const useGetLanguages = slug => {
  const [appname, endPath] = slug.split('/', 5).slice(3, 5)

  const data = useStaticQuery(graphql`
    query whatsnewLanguagesQuery {
      allMdx(filter: { fields: { slug: { glob: "/whatsnew/**" } } }) {
        nodes {
          fields {
            slug
          }
        }
      }
    }
  `)
  return data.allMdx.nodes
    .filter(({ fields: { slug } }) => {
      const [pageAppname, pageEndPath] = slug.split('/', 5).slice(3, 5)
      return appname === pageAppname && endPath === pageEndPath
    })
    .map(({ fields: { slug } }) => slug.split('/', 5)[2])
}
