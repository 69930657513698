import React from 'react'
import styled, { ThemeProvider as Provider } from 'styled-components'
import { layout, space, typography } from 'styled-system'
import { default as theme } from './theme'
import shouldForwardProp from '@styled-system/should-forward-prop'

const globalStyle = {
  '& *': {
    boxSizing: 'border-box',
  },
}

const Root = styled('div').withConfig({ shouldForwardProp })(globalStyle, layout, space, typography)

export const ThemeProvider = ({ children, ...props }) => (
  <Provider theme={{ ...theme }}>
    <Root fontFamily="sans" {...props}>
      {children}
    </Root>
  </Provider>
)

ThemeProvider.displayName = 'ThemeProvider'
