import { transparentize } from 'polished'
// import { createGlobalStyle } from 'styled-components'

// export const GlobalStyles = createGlobalStyle`
//   * {
//     -webkit-font-smoothing: antialiased;
//     box-sizing: border-box;
//     text-size-adjust: 100%;
//   }
//   body {
//     margin: 0;
//   }
// `

const createMediaQuery = n => `@media screen and (min-width:${n})`

const addAliases = (arr, aliases) =>
  aliases.forEach((key, i) =>
    Object.defineProperty(arr, key, {
      enumerable: false,
      get() {
        return this[i]
      }
    })
  )

export const breakpoints = ['640px', '768px', '1024px', '1200px']

export const mediaQueries = breakpoints.map(createMediaQuery)

const aliases = ['sm', 'md', 'lg', 'xl']

addAliases(breakpoints, aliases)
addAliases(mediaQueries, aliases)

//                    0  1  2   3   4   5    6
export const space = [0, 4, 8, 16, 32, 80, 150]

// https://bitsofco.de/the-new-system-font-stack/
export const fontFamily = `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif`
export const fonts = { sans: fontFamily }

//                        0    1   2   3   4   5    6
export const fontSizes = [12, 14, 16, 18, 24, 32, 48].map(px => `${px / 16}rem`)

export const light = 400
export const regular = 400
export const semibold = 500
export const bold = 600

// styled-system's `fontWeight` function can hook into the `fontWeights` object
export const fontWeights = {
  light,
  regular,
  semibold,
  bold
}

// color palette
const black = '#000'
const white = '#fff'
const blue = 'blue'
const text = '#001833'
const sidebarblue = '#F5F7F9'
const footerlightblue = transparentize(0.5, sidebarblue)
// TODO okay to use transparency? or should mix with white?

const aqua = '#3A8BBB' // primary colour for buttons/links

// footer text
const bluegray = '#637281'
const darkbluegray = '#4B5158'

// 6 shades of gray
const graytext = '#464646'
const grayapptitle = '#585858'
const graylabeltext = '#696969'
const grayheading = '#747474'
const graybuttonborder = '#CCCCCC'
const lightgreyborder = '#F0F0F0'

const errorlabelred = '#dd2c00'

const labelplaceholder = 'rgba(0,0,0,.54)'
const inputborder = 'rgba(0,0,0,0.12)'
const inputfocusbc = '#F9F9F9'
const inputinvalidbc = 'hsla(0, 100%, 97%, 1)'

const colors = {
  black,
  white,
  blue,
  text,
  aqua,

  bluegray,
  darkbluegray,

  sidebarblue,
  footerlightblue,

  graytext,
  grayapptitle,
  graylabeltext,
  grayheading,
  graybuttonborder,
  lightgreyborder,

  labelplaceholder,
  errorlabelred,
  inputborder,
  inputfocusbc,
  inputinvalidbc
}

export { colors }

//
// const buttons = {
//   primary: {
//     color: 'white',
//     backgroundColor: buttonblue,
//     '&:hover': {
//       backgroundColor: darken(0.1, buttonblue),
//     }
//   }
// }

// styled-system's `borderRadius` function can hook into the `radii` object/array
export const radii = [0, 2, 4]
export const radius = '2px'

export const maxContainerWidth = '1280px'

const theme = {
  breakpoints,
  mediaQueries,
  space,
  fonts,
  fontFamily,
  fontSizes,
  fontWeights,
  regular,
  bold,
  colors,
  radii,
  radius,
  maxContainerWidth
}

export default theme
