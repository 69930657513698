import styled from 'styled-components'
import { space, width, maxWidth, fontSize, color, fontFamily } from 'styled-system'
import shouldForwardProp from '@styled-system/should-forward-prop'
import { Box } from './Primitives'

/**
 * Main component.
 *
 * This component renders a main element with some responsive styles.
 *
 * @component
 * @example
 * // Usage example:
 * <Main p={3} maxWidth={1200}>
 *   Main content goes here
 * </Main>
 *
 * @param {Object} props - The props that are passed to the component.
 * @returns {JSX.Element} The Main component.
 */
export const Main = styled.main.withConfig({ shouldForwardProp })(
  {
    overflowY: 'auto',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    fontFamily: 'inherit',

    '@media screen and (max-width: 640px)': {
      overflowY: 'initial',
    },
  },
  space,
  width,
  maxWidth,
  fontSize,
  fontFamily,
  color,
)

/**
 * GrowBox component.
 *
 * This component renders a Box element that grows to fill the available space.
 * It inherits the font family from its parent and applies the flex-grow property.
 *
 * @component
 * @example
 * // Usage example:
 * <GrowBox p={3}>
 *   Growable content goes here
 * </GrowBox>
 *
 * @param {Object} props - The props that are passed to the component.
 * @returns {JSX.Element} The GrowBox component.
 */
export const GrowBox = styled(Box).withConfig({ shouldForwardProp })({
  fontFamily: 'inherit',
  flexGrow: 1,
})

/**
 * NoShrinkBox component.
 *
 * This component renders a div element that does not shrink.
 * It applies the flex-shrink property to prevent shrinking.
 *
 * @component
 * @example
 * // Usage example:
 * <NoShrinkBox p={3}>
 *   Non-shrinkable content goes here
 * </NoShrinkBox>
 *
 * @param {Object} props - The props that are passed to the component.
 * @returns {JSX.Element} The NoShrinkBox component.
 */
export const NoShrinkBox = styled.div.withConfig({ shouldForwardProp })({
  flexShrink: 0,
})
