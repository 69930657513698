import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Logo, LogoNormalLink } from './Logo'
import { Main, GrowBox, NoShrinkBox } from './Main'
import { Footer } from './Footer'
import shouldForwardProp from '@styled-system/should-forward-prop'
import { Box, Flex } from './Primitives'

/* Usage:

  <SideBySideLayout>
    // Main page content
  </SideBySideLayout>

*/

const Left = styled(Flex).withConfig({ shouldForwardProp })`
  display: flex
  flex-direction: column
  align-items: flex-end

  @media screen and (max-width:640px) {
    align-items: center;
  }
`

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: minmax(220px, 50vw) minmax(600px, auto);
  height: 100vh;
  font-family: inherit;

  @media screen and (max-width: 820px) {
    grid-template-columns: 220px auto;
  }

  @media screen and (max-width: 640px) {
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr;
    height: min-content;
  }
`

const Content = ({ children, showFooter = false, currentSite, LinkComponent, ...props }) => (
  <Main bg="white" {...props}>
    <GrowBox px={[3, 3, 4, 4, 5]} py={3} pt={[5, 6]} maxWidth={900}>
      {children}
    </GrowBox>
    {showFooter && (
      <NoShrinkBox>
        <Footer
          mt={4}
          px={[3, 3, 4, 4, 5]}
          py={[4, 5]}
          bg="footerlightblue"
          currentSite={currentSite}
          LinkComponent={LinkComponent}
        />
      </NoShrinkBox>
    )}
  </Main>
)

/**
 * SideBySideLayout component.
 *
 * This component renders a side-by-side layout with a sidebar on the left and main content on the right.
 * The sidebar contains a logo, which can be rendered using a router link or a normal link based on the `useRouterLink` prop.
 * The main content area can optionally include a footer.
 *
 * @component
 * @example
 * // Usage example:
 * <SideBySideLayout
 *   useRouterLink={true}
 *   showFooter={true}
 *   LinkComponent={YourLinkComponent}
 * >
 *   Main content goes here
 * </SideBySideLayout>
 *
 * @param {Object} props - The props that are passed to the component.
 * @param {React.ReactNode} props.children - The children elements to render inside the main content area.
 * @param {boolean} [props.useRouterLink=true] - Determines whether to use the router link or a normal link for the logo.
 * @param {boolean} [props.showFooter=false] - Determines whether to show the footer in the main content area.
 * @param {React.ComponentType} props.LinkComponent - The custom link component to render the logo link.
 * @returns {JSX.Element} The SideBySideLayout component.
 */
export const SideBySideLayout = ({
  children,
  useRouterLink = true,
  showFooter = false,
  LinkComponent,
  ...rest
}) => {
  return (
    <Container>
      <Left bg="sidebarblue">
        <Box pt={[4, '30vh']} px={[4, 4, 4, 5]} pb={4}>
          {useRouterLink ? <Logo LinkComponent={LinkComponent} /> : <LogoNormalLink />}
        </Box>
      </Left>
      <Content showFooter={showFooter} {...rest} LinkComponent={LinkComponent}>
        {children}
      </Content>
    </Container>
  )
}

SideBySideLayout.propTypes = {
  children: PropTypes.node,
  useRouterLink: PropTypes.bool,
  showFooter: PropTypes.bool,
  LinkComponent: PropTypes.elementType.isRequired,
  ...Content.propTypes,
}
