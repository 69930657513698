import React, { useEffect, useState } from 'react'
import {
  Tab as BaseTab,
  TabList as BaseTabList,
  TabPanel as BaseTabPanel,
  Tabs as BaseTabs
} from 'react-tabs'
import styled from 'styled-components'
import createPersistedState from 'use-persisted-state'

const borderColor = '#ccc'

const TabList = styled(BaseTabList)`
  /* border-bottom: 1px solid ${borderColor}; */
  margin: 0;
  margin-top: 1em;
  padding: 0;
`

const TabHeading = styled(BaseTab).attrs({
  selectedClassName: 'selected',
  disabledClassName: 'disabled'
})`
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 1.5em 5px;
  margin: 0;
  cursor: pointer;
  color: #808080;

  &.selected {
    background: #fff;
    border-color: ${borderColor};
    color: inherit;
    border-radius: 5px 5px 0 0;
  }
  &.disabled {
    color: GrayText;
    cursor: default;
  }
  &:focus {
    color: #1e88e5;
    outline: none;
  }
`

const TabPanel = styled(BaseTabPanel)`
  display: none;

  border: 1px solid ${borderColor};
  border-radius: 5px;

  ${({ selected, index }) =>
    selected && index === 0 ? `border-top-left-radius: 0;` : ``}

  padding: 1.5em;
  @media screen and (max-width: 640px) {
    padding: 0.75em;
  }

  &.react-tabs__tab-panel--selected {
    display: block;
  }
`

export const Tabs = ({ children, id = 'selected' }) => {
  const useSelectedTabState = createPersistedState(`${id}Tab`)
  const isSSR = typeof window === 'undefined'
  const [selectedTabIndex, setSelectedTabIndex] = useSelectedTabState(0)
  const [selectedTab, setSelectedTab] = useState(0)

  useEffect(() => {
    if (!isSSR) {
      // after rehydration clientside, on first render
      // see https://reactjs.org/docs/react-dom.html#hydrate
      setSelectedTab(selectedTabIndex)
    }
  }, [isSSR, selectedTabIndex])

  return (
    <BaseTabs
      selectedIndex={selectedTab}
      onSelect={index => setSelectedTabIndex(index)}
    >
      <TabList>
        {children.map(child => (
          <TabHeading key={child.props.name}>{child.props.name}</TabHeading>
        ))}
      </TabList>

      {children.map((child, i) => (
        <TabPanel key={child.props.name} index={i}>
          {child.props.children}
        </TabPanel>
      ))}
    </BaseTabs>
  )
}

export const Tab = ({ children }) => {
  return <>{children}</>
}
