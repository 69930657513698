import shouldForwardProp from '@styled-system/should-forward-prop'
import styled from 'styled-components'

const Triangle = styled.div.withConfig({ shouldForwardProp })(props => ({
  display: 'inline-block',
  width: 0,
  height: 0,
  verticalAlign: 'middle',
  borderRight: '0.3125em solid transparent',
  borderLeft: '0.3125em solid transparent',
  borderTop: '0.4375em solid',
  color: 'rgb(133, 133, 133)',
  transformOrigin: '50% 50%',
  transition: 'all 100ms ease-out',
  fontSize: 12,
  transform: props.rotated ? 'rotate(0deg)' : 'rotate(-90deg)'
}))

export default Triangle
