import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import urljoin from 'url-join'

const SchemaOrgJSON = ({
  title,
  description,
  image,
  logoURL,
  postURL,
  siteUrl,
  isArticle
}) => {
  let schema = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: siteUrl,
      name: title
    }
  ]
  if (isArticle) {
    // TODO more breadcrumbs?
    schema.push(
      {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@id': postURL,
              name: title,
              image
            }
          }
        ]
      },
      {
        '@context': 'http://schema.org',
        '@type': 'Article',
        url: siteUrl,
        headline: title,
        image: {
          '@type': 'ImageObject',
          url: image
        },
        description: description,
        datePublished: '2018-09-01T00:00:00+08:00',
        dateModified: '2018-09-01T00:00:00+08:00',
        author: {
          '@type': 'Organization',
          name: 'Moleskine Digital Studio',
          logo: {
            '@type': 'ImageObject',
            url: logoURL
          }
        },
        publisher: {
          '@type': 'Organization',
          name: 'Moleskine Digital Studio',
          logo: {
            '@type': 'ImageObject',
            url: logoURL
          }
        },
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': postURL
        }
      }
    )
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

const OpenGraph = ({
  url,
  isArticle,
  title,
  description,
  image,
  imageWidth,
  imageHeight,
  appID
}) => (
  <Helmet>
    <meta property="og:url" content={url} />
    <meta property="og:type" content={isArticle ? 'article' : 'website'} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />
    {imageWidth && <meta property="og:image:width" content={imageWidth} />}
    {imageHeight && <meta property="og:image:height" content={imageHeight} />}
    <meta property="fb:app_id" content={appID} />
  </Helmet>
)

OpenGraph.propTypes = {
  url: PropTypes.string,
  isArticle: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  appID: PropTypes.string
}

const TwitterCard = ({ site, creator, title, description, image }) => (
  <Helmet>
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content={site ? site : ''} />
    <meta name="twitter:creator" content={creator ? creator : ''} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={image} />
  </Helmet>
)

TwitterCard.propTypes = {
  site: PropTypes.string,
  creator: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string
}

export const useSiteConfig = () => {
  const config = useStaticQuery(graphql`
    query config {
      site {
        siteMetadata {
          siteUrl
          siteTitle
          siteDescription
          siteFacebookAppID
          siteTwitterID
        }
      }
      siteLogo: file(
        sourceInstanceName: { eq: "img" }
        relativePath: { eq: "mds_seo.png" }
      ) {
        childImageSharp {
          resize(width: 800, quality: 90, toFormat: PNG) {
            src
            width
            height
          }
        }
      }
    }
  `)

  return {
    ...config.site.siteMetadata,
    siteLogo: config.siteLogo.childImageSharp.resize
  }
}

const SEO = ({
  title = null,
  description = null,
  image = null,
  path = '',
  isArticle = false,
  noindex = false
}) => {
  const {
    siteUrl,
    siteLogo,
    siteTitle,
    siteDescription,
    siteFacebookAppID,
    siteTwitterID
  } = useSiteConfig()

  // If not given then use site default
  if (!image) image = siteLogo
  if (!title) title = siteTitle
  if (!description) description = siteDescription

  // Absolute URLs
  const url = urljoin(siteUrl, path)
  const logoURL = urljoin(siteUrl, siteLogo.src)
  const imageURL = urljoin(siteUrl, image.src)

  return (
    <Fragment>
      <Helmet>
        {/* General tags */}
        <meta name="description" content={description} />
        <meta name="image" content={imageURL} />
        {noindex ? <meta name="robots" content="noindex" /> : null}
      </Helmet>

      {/* Schema.org tags */}
      <SchemaOrgJSON
        title={title}
        description={description}
        image={imageURL}
        logoURL={logoURL}
        postURL={url}
        siteUrl={siteUrl}
        isArticle={isArticle}
      />

      {/* OpenGraph tags */}
      <OpenGraph
        title={title}
        description={description}
        image={imageURL}
        imageWidth={image.width}
        imageHeight={image.height}
        url={url}
        appID={siteFacebookAppID}
        isArticle={isArticle}
      />

      {/* Twitter Card tags */}
      <TwitterCard
        title={title}
        description={description}
        image={imageURL}
        site={siteTwitterID}
        creator={siteTwitterID}
      />
    </Fragment>
  )
}

SEO.propTypes = {
  // config: PropTypes.object.isRequired,
  // postNode: PropTypes.object,
  // postPath: PropTypes.string,
  // postCategory: PropTypes.string
}

export default SEO
