import React from 'react'
import styled from 'styled-components'

const Anchor = styled.a`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;

  &:focus {
    padding: 0.5rem 0.75rem;
    position: fixed;
    top: 10px;
    left: 10px;
    background: transparent;
    z-index: 1;
    width: auto;
    height: auto;
    clip: auto;
    color: #585858;
    text-decoration: none;
    font-size: 1em;
  }
`

const AbsoluteAnchor = styled(Anchor)`
  &:focus {
    position: absolute;
    left: 0;
    top: 0;
  }
`

export const skipLinkID = 'content'

export const SkipToContent = () => (
  <Anchor href={`#${skipLinkID}`}>Skip to content</Anchor>
)

const gotoNav = e => {
  if (e.ctrlKey || e.metaKey || e.altKey || e.shiftKey) return
  const nav = document.querySelector('[aria-current=page]')
  if (nav) nav.focus()
  e.preventDefault()
}

export const SkipToNavigation = () => (
  <AbsoluteAnchor
    href={`#nav`}
    onClick={gotoNav}
    onKeyDown={e => {
      if (e.key === 'Enter' || e.key === ' ') gotoNav(e)
    }}
  >
    Skip to navigation
  </AbsoluteAnchor>
)

const ContentDiv = styled.div`
  /* place at top of page to prevent page from shifting down */
  position: absolute;
  top: 0;
`

export const SkipNavContent = () => {
  return <ContentDiv tabIndex={-1} id={skipLinkID} />
}
