import { Box } from 'mds-web-ui'
import PropTypes from 'prop-types'
import React from 'react'

const BoxLink = props => (
  <Box
    mb="0.2em"
    ml={3}
    color="#9B9B9B"
    className="toc"
    css={{
      display: 'list-item',
      listStyle: 'disc',
      listStylePosition: 'outside',
      fontSize: '16px',
      'a, a:visited': {
        lineHeight: 1.2,
        fontSize: 'inherit',
        color: '#585858',
        paddingBottom: '2px'
      }
    }}
  >
    <a {...props} />
  </Box>
)

const shouldShowTOC = toc =>
  toc &&
  toc.items &&
  toc.items.filter(({ title }) => title && title.length).length > 1

const TOC = ({ tableOfContents }) => {
  return shouldShowTOC(tableOfContents) ? (
    <Box
      mt={3}
      px={[3, 4]}
      py={[2, 3]}
      bg="#fafbfc"
      css={{
        borderRadius: '5px',
        columnCount: tableOfContents.items.length > 5 ? 2 : 1
      }}
    >
      {tableOfContents.items
        .filter(item => item.title)
        .map(item => (
          <BoxLink href={item.url} key={item.url}>
            {item.title}
          </BoxLink>
        ))}
    </Box>
  ) : null
}

TOC.propTypes = {
  tableOfContents: PropTypes.shape({
    items: PropTypes.array
  })
}

export { TOC as default }
