import React from 'react'
import { Button } from './Button'

/**
 * PrimaryButton component.
 *
 * This component renders a button with a primary style.
 *
 * @component
 * @example
 * // Usage example:
 * <PrimaryButton onClick={handleClick}>Primary</PrimaryButton>
 *
 * @returns {JSX.Element} The PrimaryButton component.
 */
export const PrimaryButton = (props) => (
  <Button bg="sidebarblue" color="aqua" borderColor="aqua" {...props} />
)

/**
 * SecondaryButton component.
 *
 * This component renders a button with a secondary style.
 *
 * @component
 * @example
 * // Usage example:
 * <SecondaryButton onClick={handleClick}>Secondary</SecondaryButton>
 *
 * @returns {JSX.Element} The SecondaryButton component.
 */
export const SecondaryButton = (props) => (
  <Button bg="white" color="graylabeltext" borderColor="graybuttonborder" {...props} />
)

/**
 * ButtonLink component.
 *
 * This component renders a button styled as a link that opens in a new tab.
 *
 * @component
 * @example
 * // Usage example:
 * <ButtonLink href="https://google.com">Go to Google</ButtonLink>
 *
 * @returns {JSX.Element} The ButtonLink component.
 */
export const ButtonLink = (props) => <SecondaryButton {...props} as="a" target="_blank" />
