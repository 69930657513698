import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space } from 'styled-system'
import shouldForwardProp from '@styled-system/should-forward-prop'

const Nav = styled.nav.withConfig({ shouldForwardProp })`
  ${space};
`

/**
 * Navbar component.
 *
 * This component renders a basic nav element.
 *
 * @component
 * @example
 * // Usage example:
 * <Navbar>
 *   <a href="/home">Home</a>
 *   <a href="/about">About</a>
 * </Navbar>
 *
 * @param {Object} props - The props that are passed to the component.
 * @param {React.ReactNode} props.children - The children elements to render inside the navigation bar.
 * @returns {JSX.Element} The Navbar component.
 */
export const Navbar = ({ children }) => (
  <Nav px={4} pb={3}>
    {children}
  </Nav>
)

Navbar.propTypes = {
  children: PropTypes.node,
}
