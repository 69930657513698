import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, width, fontSize, color } from 'styled-system'

import { Main, GrowBox, NoShrinkBox } from './Main'
import { Footer } from './Footer'
import { NeedHelp } from './NeedHelp'

import { GridCenteredCol } from './Grid'
import { Logo, LogoNormalLink } from './Logo'
import theme from './Theme/theme'
import shouldForwardProp from '@styled-system/should-forward-prop'

/* Usage:

  <SidebarLayout>
    <Sidebar>
      // Three children - top, middle, bottom
    </Sidebar>
    <Content>
      // Main page content
    </Content>
  </SidebarLayout>

*/

/**
 * SidebarLogo component.
 *
 * This component renders a logo inside a centered grid column. It conditionally uses a router link or a normal link
 * based on the `useRouterLink` prop.
 *
 * @component
 * @example
 * // Usage example:
 * <SidebarLogo LinkComponent={YourLinkComponent} useRouterLink={true} />
 *
 * @param {Object} props - The props that are passed to the component.
 * @param {React.ComponentType} props.LinkComponent - The custom link component to render the logo link.
 * @param {boolean} [props.useRouterLink=true] - Determines whether to use the router link or a normal link.
 * @returns {JSX.Element} The SidebarLogo component.
 */
export const SidebarLogo = ({ LinkComponent, useRouterLink = true }) => (
  <GridCenteredCol px={4}>
    {useRouterLink ? <Logo LinkComponent={LinkComponent} /> : <LogoNormalLink />}
  </GridCenteredCol>
)

SidebarLogo.propTypes = {
  LinkComponent: PropTypes.elementType.isRequired,
  useRouterLink: PropTypes.bool,
}

export const Sidebar = styled.div`
  ${space};
  ${width};
  ${fontSize};
  background-color: ${theme.colors.sidebarblue};
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  overflow-y: auto;

  /* logo, nav, return to actions */
  grid-template-rows: 150px 1fr 200px;

  @media screen and (max-width: 640px) {
    height: min-content;
    grid-template-rows: 80px auto min-content;

    /* align nav links right */
    text-align: right;
  }
`

/**
 * Content component.
 *
 * 300 wide sidebar + up to 900px wide content = 1200 max page width.
 * The help and footer sections are conditionally rendered based on the `showHelp` and `showFooter` props.
 *
 * @component
 * @example
 * // Usage example:
 * <Content
 *   showHelp={true}
 *   showFooter={true}
 *   currentSite="home"
 *   ButtonLinkComponent={YourButtonLinkComponent}
 *   LinkComponent={YourLinkComponent}
 * >
 *   Main content goes here
 * </Content>
 *
 * @param {Object} props - The props that are passed to the component.
 * @param {React.ReactNode} props.children - The children elements to render inside the content area.
 * @param {Array} [props.px=[4, 4, 4, 5]] - The horizontal padding of the content area.
 * @param {boolean} [props.showHelp=false] - Determines whether to show the help section.
 * @param {boolean} [props.showFooter=false] - Determines whether to show the footer section.
 * @param {string} [props.currentSite] - The current site context, which is passed to the footer.
 * @param {Object} [props.mainProps={}] - Additional props to pass to the `Main` component.
 * @param {React.ComponentType} props.ButtonLinkComponent - The custom link component to render the help link.
 * @param {React.ComponentType} props.LinkComponent - The custom link component to render the footer links.
 * @param {Object} props.ref - The ref to forward to the `Main` component.
 * @returns {JSX.Element} The Content component.
 */
export const Content = forwardRef(
  (
    {
      children,
      px = [4, 4, 4, 5],
      showHelp = false,
      showFooter = false,
      currentSite,
      mainProps = {},
      ButtonLinkComponent,
      LinkComponent,
      ...props
    },
    ref,
  ) => {
    return (
      <Main ref={ref} {...mainProps}>
        <GrowBox px={px} pb={3} pt={[5, 6]} maxWidth={900} {...props}>
          {children}
        </GrowBox>
        {(showHelp || showFooter) && (
          <NoShrinkBox>
            {showHelp && <NeedHelp px={px} LinkComponent={ButtonLinkComponent} />}
            {showFooter && (
              <Footer
                mt={4}
                px={px}
                py={[4, 5]}
                bg="footerlightblue"
                currentSite={currentSite}
                LinkComponent={LinkComponent}
              />
            )}
          </NoShrinkBox>
        )}
      </Main>
    )
  },
)

Content.propTypes = {
  children: PropTypes.node,
  px: PropTypes.array,
  showHelp: PropTypes.bool,
  showFooter: PropTypes.bool,
  currentSite: Footer.propTypes.currentSite,
  mainProps: PropTypes.object,
  ButtonLinkComponent: PropTypes.elementType.isRequired,
  LinkComponent: PropTypes.elementType.isRequired,
}

/* ------ */

const Container = styled.div.withConfig({ shouldForwardProp })`
  ${color};
  display: grid;
  grid-template-columns: 1fr minmax(auto, 1200px) 1fr;

  @media screen and (max-width: 640px) {
    grid-template-columns: 0 auto;
  }
`

const SidebarSpace = styled.div.withConfig({ shouldForwardProp })`
  background-color: ${theme.colors.sidebarblue};
`

const Page = styled.div`
  display: grid;
  grid-column: span 2;
  grid-template-rows: 1fr;
  grid-template-columns: 300px 1fr;
  height: 100vh;

  @media screen and (max-width: 750px) {
    grid-template-columns: 220px 1fr;
  }

  @media screen and (max-width: 640px) {
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr;
    height: min-content;
  }
`

export const SidebarLayout = ({ children, bg = null }) => (
  <Container bg={bg}>
    <SidebarSpace bg="sidebarblue" />
    <Page>{children}</Page>
  </Container>
)

SidebarLayout.propTypes = {
  children: PropTypes.node,
}
