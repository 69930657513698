import shouldForwardProp from '@styled-system/should-forward-prop'
import styled from 'styled-components'
import { space } from 'styled-system'

/**
 * GridCentered component.
 *
 * This component renders a div that centers its content both horizontally and vertically.
 *
 * @component
 * @example
 * // Usage example:
 * <GridCentered m={3} p={2}>
 *   Centered Content
 * </GridCentered>
 *
 * @param {Object} props - The props that are passed to the component.
 * @returns {JSX.Element} The GridCentered component.
 */
export const GridCentered = styled.div(
  {
    justifySelf: 'center',
    alignSelf: 'center',
  },
  space,
)

/**
 * GridCenteredRow component.
 *
 * This component renders a div that centers its content horizontally.
 *
 * @component
 * @example
 * // Usage example:
 * <GridCenteredRow m={3} p={2}>
 *   Centered Row Content
 * </GridCenteredRow>
 *
 * @param {Object} props - The props that are passed to the component.
 * @returns {JSX.Element} The GridCenteredRow component.
 */
export const GridCenteredRow = styled.div.withConfig({ shouldForwardProp })(
  {
    justifySelf: 'center',
  },
  space,
)

/**
 * GridCenteredCol component.
 *
 * This component renders a div that centers its content vertically.
 *
 * @component
 * @example
 * // Usage example:
 * <GridCenteredCol m={3} p={2}>
 *   Centered Column Content
 * </GridCenteredCol>
 *
 * @param {Object} props - The props that are passed to the component.
 * @returns {JSX.Element} The GridCenteredCol component.
 */
export const GridCenteredCol = styled.div.withConfig({ shouldForwardProp })(
  {
    alignSelf: 'center',
  },
  space,
)
