import React from 'react'
import styled from 'styled-components'

const Icon = styled.img`
  width: ${({ width }) => `${width}px`};
  height: 24px;
  vertical-align: text-bottom;
`

export const TimepageIcon = ({ src, width = 24 }) => {
  let img
  try {
    img = require(`../../../support/timepage/assets/${src}`).default
  } catch (error) {
    console.error('Can not find icon ${src}')
    throw error
  }
  return <Icon src={img} width={width} />
}

export const ActionsIcon = ({ src, width = 24 }) => {
  let img
  try {
    img = require(`../../../support/actions/assets/${src}`).default
  } catch (error) {
    console.error('Can not find icon ${src}')
    throw error
  }
  return <Icon src={img} width={width} />
}

export const FlowIcon = ({ src, width = 24 }) => {
  let img
  try {
    img = require(`../../../support/flow/assets/${src}`).default
  } catch (error) {
    console.error('Can not find icon ${src}')
    throw error
  }
  return <Icon src={img} width={width} />
}
