import { Box } from 'mds-web-ui'
import React from 'react'

export const Screenshot = ({
  children,
  justify = false,
  className,
  maxWidth
}) => {
  const allClassNames = [className]
  if (!justify) {
    allClassNames.push('center')
  }

  const classNames = allClassNames.join(' ')

  return (
    <Box className={classNames} maxWidth={maxWidth}>
      {children}
    </Box>
  )
}
