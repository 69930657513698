import styled from 'styled-components'
import shouldForwardProp from '@styled-system/should-forward-prop'
import { space, layout, typography, color } from 'styled-system'

/**
 * ErrorLabel component.
 *
 * This component renders a red text label that indicates an error has occurred.
 *
 * @component
 * @example
 * // Usage example:
 * <ErrorLabel>An error has occurred</ErrorLabel>
 *
 * @param {Object} props - The props that are passed to the component.
 * @returns {JSX.Element} The ErrorLabel component.
 */
export const ErrorLabel = styled.div.withConfig({ shouldForwardProp }).attrs((props) => ({
  color: 'errorlabelred',
  fontSize: 0,
  ...props,
}))(
  {
    textAlign: 'center',
  },
  color,
  space,
  layout,
  typography,
)
