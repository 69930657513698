import { Box, Flex, Relative, Text } from 'mds-web-ui'
import { mapValues, sortBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import Triangle from '../Docs/Triangle'
import { Link } from '../Link'
import { useGetLanguages } from './languages'

const languageNames = {
  de: 'German (Deutsch)',
  en: 'English (US)',
  'en-gb': 'English (UK)',
  es: 'Spanish (Español)',
  fr: 'French (Français)',
  it: 'Italian (Italiano)',
  ja: 'Japanese (日本)',
  ko: 'Korean (한국인)',
  'pt-br': (
    <>
      Brazilian Portuguese
      <br />
      (Português do Brasil)
    </>
  ),
  ru: 'Russian (Pусский)',
  tr: 'Turkish (Türkçe)',
  'zh-hans': 'Simplified Chinese (简化字)',
  'zh-hant': 'Traditional Chinese (正體字)'
}

const languageNamesPlain = mapValues(languageNames, (val, key) =>
  key === 'pt-br' ? 'Brazilian Portuguese' : val
)

const getCurrentLanguage = slug => {
  if (slug.startsWith('/new/') || slug.startsWith('/whatsnew/')) {
    return slug.split('/', 4)[2]
  } else if (slug.startsWith('/support/')) {
    // all support pages are en for now
    return 'en'
  }
}

const DropdownItem = ({ children, to }) => (
  <Link
    to={to}
    lineHeight={1.8}
    fontSize={0}
    px={2}
    py={1}
    role="option"
    css={{
      boxShadow: 'none !important',
      whiteSpace: 'nowrap',
      '&:focus': {
        outline: 'none',
        boxShadow: '0 0 0 2px #5C89BA !important',
        borderRadius: 2
      }
    }}
  >
    {children}
  </Link>
)

const DropdownWrapper = ({ expanded, selected, ...props }) => (
  <Flex
    as="button"
    alignItems="center"
    mr={-2}
    p={2}
    aria-haspopup="listbox"
    aria-expanded={expanded}
    aria-label={`${selected} language. Change language`}
    css={{
      height: 'fit-content',
      cursor: 'pointer',
      border: 'none',
      background: 'none',
      borderRadius: 2,
      '&:focus': {
        outline: 'none',
        textDecoration: 'underline',
        textDecorationColor: '#5C89BA'
      }
    }}
    {...props}
  />
)

const DropdownModal = props => (
  <Box
    bg="white"
    p={2}
    role="listbox"
    css={{
      position: 'absolute',
      right: 0,
      top: '100%',
      border: '1px solid gray',
      borderRadius: 3,
      zIndex: 100,
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(170px, 1fr))',
      gridColumnGap: 10,
      maxWidth: 'min(calc(100vw - 32px), 500px)'
    }}
    {...props}
  />
)

const Dropdown = ({ selected, selectedPlain, children }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <Relative
      onBlur={e => {
        // let us blur if we're now focusing a child element
        if (!e.currentTarget.contains(e.relatedTarget)) setExpanded(false)
      }}
    >
      <DropdownWrapper
        expanded={expanded}
        selected={selectedPlain}
        onClick={() => {
          setExpanded(e => !e)
        }}
      >
        <Triangle color="aqua" rotated={expanded} fontSize={14} />
        <Text fontSize={0} color="aqua" lineHeight={1.2} ml={1}>
          {selected}
        </Text>
      </DropdownWrapper>
      {expanded ? <DropdownModal>{children}</DropdownModal> : null}
    </Relative>
  )
}

const LanguageSelector = ({ baseURL, slug }) => {
  const languages = useGetLanguages(slug)
  const [appname, endPath] = slug.split('/', 5).slice(3, 5)

  // no choice - don't show
  if (languages.length <= 1) return null

  const currentLang = getCurrentLanguage(slug)

  return (
    <Dropdown
      selected={languageNames[currentLang]}
      selectedPlain={languageNamesPlain[currentLang]}
    >
      {sortBy(languages, l => languageNamesPlain[l]).map(lang => (
        <DropdownItem
          to={`/${baseURL}/${lang}/${appname}/${endPath}`}
          key={lang}
        >
          {languageNames[lang]}
        </DropdownItem>
      ))}
    </Dropdown>
  )
}

LanguageSelector.propTypes = {
  baseURL: PropTypes.string,
  slug: PropTypes.string
}

export default LanguageSelector
