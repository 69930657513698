import shouldForwardProp from '@styled-system/should-forward-prop'
import React from 'react'
import styled from 'styled-components'
import { display, maxWidth, position, space, layout, color, flexbox, grid } from 'styled-system'
import { Box } from './Primitives'

/**
 * Position component.
 *
 * A basic layout component that can be used to apply specific position styles such as relative, fixed, or absolute.
 *
 * @component
 * @example
 * // Usage example:
 * <Position p={3}>
 *   Positioned Content
 * </Position>
 *
 * @param {Object} props - The props that are passed to the component.
 * @param {Object} [props.css] - Custom CSS styles to apply to the component.
 * @returns {JSX.Element} The Position component.
 */
export const Position = styled('div').withConfig({ shouldForwardProp })(
  {},
  (props) => props.css,
  space,
  layout,
  color,
  flexbox,
  grid,
  position,
)

/**
 * Relative component.
 *
 * A basic layout component that applies relative positioning to its children.
 *
 * @component
 * @example
 * // Usage example:
 * <Relative p={3}>
 *   Relative Content
 * </Relative>
 *
 * @param {Object} props - The props that are passed to the component.
 * @param {Object} [props.css] - Custom CSS styles to apply to the component.
 * @returns {JSX.Element} The Relative component.
 */
export const Relative = styled(Position)({
  position: 'relative',
})

/**
 * Fixed component.
 *
 * A basic layout component that applies fixed positioning to its children.
 *
 * @component
 * @example
 * // Usage example:
 * <Fixed p={3} left={25} top={50}>
 *   Fixed Content
 * </Fixed>
 *
 * @param {Object} props - The props that are passed to the component.
 * @param {Object} [props.css] - Custom CSS styles to apply to the component.
 * @returns {JSX.Element} The Fixed component.
 */
export const Fixed = styled(Position)({
  position: 'fixed',
})

/**
 * Absolute component.
 *
 * A basic layout component that applies absolute positioning to its children.
 *
 * @component
 * @example
 * // Usage example:
 * <Absolute p={3} left={25} top={50}>
 *   Absolute Content
 * </Absolute>
 *
 * @param {Object} props - The props that are passed to the component.
 * @param {Object} [props.css] - Custom CSS styles to apply to the component.
 * @returns {JSX.Element} The Absolute component.
 */
export const Absolute = styled(Position)({
  position: 'absolute',
})

const Display = styled(Box).withConfig({ shouldForwardProp })(display)

/**
 * Hide component.
 *
 * This component conditionally hides its children based on the provided breakpoints.
 *
 * @component
 * @example
 * // Usage example:
 * <Hide xs display="none">
 *   Hidden Content
 * </Hide>
 *
 * @param {Object} props - The props that are passed to the component.
 * @param {boolean} [props.xs] - Hide on extra small screens.
 * @param {boolean} [props.s] - Hide on small screens.
 * @param {boolean} [props.m] - Hide on medium screens.
 * @param {boolean} [props.l] - Hide on large screens.
 * @param {string} [props.display] - The display property to apply when hidden.
 * @returns {JSX.Element} The Hide component.
 */
export const Hide = ({ xs, s, m, l, display, ...props }) => {
  const allProps = {
    display: display || [xs, s, m, l].map((n) => (n ? 'none' : 'block')),
    ...props,
  }

  return <Display {...allProps} />
}

export const Container = styled(Box)
  .withConfig({ shouldForwardProp })
  .attrs((props) => ({
    mx: 'auto',
    px: 3,
    ...props,
  }))({ maxWidth: 1200 }, maxWidth)
