import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text } from '..'
import { Divider } from './Divider'

/**
 * NeedHelp component.
 *
 * This component renders a section with a divider, a text prompt, and a link for contacting support.
 *
 * @component
 * @example
 * // Usage example:
 * <NeedHelp LinkComponent={YourLinkComponent} />
 *
 * @param {Object} props - The props that are passed to the component.
 * @param {number} [props.maxWidth=900] - The maximum width of the inner box.
 * @param {Array} [props.py=[2, 3]] - The vertical padding of the inner box.
 * @param {Array} [props.px=[4, 5]] - The horizontal padding of the inner box.
 * @param {React.ComponentType} props.LinkComponent - The custom link component to render the contact link.
 * @returns {JSX.Element} The NeedHelp component.
 */
export const NeedHelp = ({ py = [2, 3], px = [4, 5], maxWidth = 900, LinkComponent, ...props }) => (
  <Box mt={3}>
    <Box {...props} maxWidth={maxWidth} py={py} px={px}>
      <Divider borderBottom="1px solid" borderColor="lightgreyborder" />
      <Text as="div" color="grayapptitle" mt={4} mb={3} fontSize={[2, 3]} fontWeight="normal">
        Need more help?
      </Text>
      <LinkComponent type="primary" href="/contact" uppercase px={4}>
        Send us a message
      </LinkComponent>
    </Box>
  </Box>
)

NeedHelp.propTypes = {
  maxWidth: PropTypes.number,
  py: PropTypes.array,
  px: PropTypes.array,
  LinkComponent: PropTypes.elementType.isRequired,
}
