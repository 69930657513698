const { document } = require('global')

exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (location.hash) {
    setTimeout(() => {
      const hash = document.querySelector(decodeURIComponent(location.hash))
      if (hash) hash.scrollIntoView()
    }, 0)
  } else {
    // navigate to content for support pages
    if (prevLocation && location.pathname.startsWith('/support/')) {
      const content = document.querySelector('#content')
      if (content) {
        content.focus()
      }
    }
  }
}
