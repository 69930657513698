import { theme } from 'mds-web-ui'
import shouldForwardProp from '@styled-system/should-forward-prop'
import PropTypes from 'prop-types'
import React from 'react'
import styled, {
  ThemeProvider as Provider,
  createGlobalStyle
} from 'styled-components'
import { layout, space, typography } from 'styled-system'

import { DocsContainer } from '../components/Docs'

const createMediaQuery = n => `@media screen and (min-width:${n})`

const addAliases = (arr, aliases) =>
  aliases.forEach((key, i) =>
    Object.defineProperty(arr, key, {
      enumerable: false,
      get() {
        return this[i]
      }
    })
  )

export const breakpoints = ['640px', '768px', '1024px', '1200px', '1400px']

export const mediaQueries = breakpoints.map(createMediaQuery)

const aliases = ['sm', 'md', 'lg', 'xl', 'xxl']

addAliases(breakpoints, aliases)
addAliases(mediaQueries, aliases)

theme.breakpoints = breakpoints
theme.mediaQueries = mediaQueries

const Root = styled('div').withConfig({ shouldForwardProp })(
  {
    '& *': {
      boxSizing: 'border-box'
    }
  },
  layout,
  space,
  typography
)

export const ThemeProvider = ({ children, ...props }) => (
  <Provider theme={{ ...theme }}>
    <Root fontFamily="sans" {...props}>
      {children}
    </Root>
  </Provider>
)

ThemeProvider.displayName = 'ThemeProvider'

const GlobalStyle = createGlobalStyle`
:root {
  // Base colors to handle overall default theme
  --msk-color-base: #fff;
  --msk-bg-base: #000;

  --msk-color: #000;
  --msk-bg: rgb(245, 247, 249);

  color: var(--msk-color);
  background-color: var(--msk-bg);
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  text-size-adjust: 100%;
}
body {
  margin: 0;
}
`

const Container = ({ children }) => (
  <>
    <GlobalStyle />
    <ThemeProvider>{children}</ThemeProvider>
  </>
)

const PageTemplate = ({ children, pageContext }) => (
  <Container>
    {pageContext.supportPage ? (
      <DocsContainer {...pageContext}>{children}</DocsContainer>
    ) : (
      children
    )}
  </Container>
)

PageTemplate.propTypes = {
  children: PropTypes.node
}

export default PageTemplate
