import { useLocation } from '@reach/router'
import { ScrollContext } from 'gatsby-react-router-scroll/scroll-handler'
import { useContext, useLayoutEffect, useRef } from 'react'

export function useScrollRestoration(identifier) {
  const location = useLocation()
  const state = useContext(ScrollContext)
  const ref = useRef(null)

  useLayoutEffect(() => {
    if (ref.current) {
      const position = state.read(location, identifier)
      ref.current.scrollTo(0, position || 0)
    }
  }, [location.key])

  return {
    ref,
    onScroll: () => {
      if (ref.current) {
        state.save(location, identifier, ref.current.scrollTop)
      }
    }
  }
}
