import shouldForwardProp from '@styled-system/should-forward-prop'
import styled from 'styled-components'
import {
  borderColor,
  borderRadius,
  borders,
  boxShadow,
  color,
  fontSize,
  lineHeight,
  space,
  width
} from 'styled-system'

export const SearchInput = styled('input')
  .withConfig({
    shouldForwardProp
  })
  .attrs(props => ({
    as: 'input',
    type: 'search',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    py: 2,
    m: 0,
    width: 1,
    border: 0,
    boxShadow: 'none',
    borderRadius: 2,
    color: 'inherit',
    bg: 'transparent',
    ...props
  }))(
  {
    fontFamily: 'inherit',
    display: 'inline-block',
    verticalAlign: 'middle',
    border: 0,
    appearance: 'none',
    '&:hover': {
      outline: 'none',
      boxShadow: '0 0 0 2px #d3e0e3'
    },
    '&:focus': {
      outline: 'none',
      boxShadow: '0 0 0 2px #5C89BA'
    },
    '&:disabled': {
      opacity: 1 / 4
    }
  },
  fontSize,
  lineHeight,
  width,
  borders,
  borderColor,
  boxShadow,
  borderRadius,
  space,
  color
)
