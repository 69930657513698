import { Box, Flex } from 'mds-web-ui'
import React from 'react'

import { Search } from '../Search'
import { SkipToNavigation } from './SkipLink'

export const DocsSearch = () => (
  <Flex pt={[2, 4]} pb={[3, 4]}>
    <Box width={[1, 300]} css={{ flexBasis: 'auto', order: 2 }}>
      <Search width={[1, 300]} bg="#F3F3F3" placeholder="Search for help..." />
    </Box>
    <Box flex={1} css={{ position: 'relative' }}>
      <SkipToNavigation />
    </Box>
  </Flex>
)
