import styled from 'styled-components'
import { border, space } from 'styled-system'
import shouldForwardProp from '@styled-system/should-forward-prop'
import { Box } from './Primitives'

/**
 * Divider component.
 *
 * This component renders a dividing line.
 *
 * @component
 * @example
 * // Usage example:
 * <Divider my={4} />
 *
 * @param {Object} props - The props that are passed to the component.
 * @returns {JSX.Element} The Divider component.
 */
export const Divider = styled(Box)
  .withConfig({ shouldForwardProp })
  .attrs((props) => ({
    my: 3,
    ...props,
    as: 'hr',
  }))(
  {
    border: 'none',
  },
  space,
  border,
)
