import shouldForwardProp from '@styled-system/should-forward-prop'
import { Link as BaseLink } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import {
  color,
  display,
  fontSize,
  fontWeight,
  layout,
  lineHeight,
  space,
  width
} from 'styled-system'

export const Link = styled(BaseLink)
  .withConfig({
    shouldForwardProp
  })
  .attrs(props => ({
    color: 'inherit',
    lineHeight: [2, 3],
    ...props
  }))(
  {
    display: 'inline-block',
    textDecoration: 'none',
    transition: 'opacity ease-in-out 0.1s',
    '&:hover': {
      opacity: 0.8
    },
    '&[aria-current]': {
      fontWeight: 'bold'
    }
  },
  props => props.css,
  space,
  width,
  color,
  fontSize,
  fontWeight,
  lineHeight,
  layout
)

export const ActiveLink = ({ to, ...props }) => <Link to={to} {...props} />

const ExternalBaseLink = styled.a
  .withConfig({ shouldForwardProp })
  .attrs(props => ({
    color: 'inherit',
    lineHeight: [2, 3],
    ...props
  }))(
  {
    display: 'inline-block',
    textDecoration: 'none',
    transition: 'opacity ease-in-out 0.1s',
    '&:hover': {
      opacity: 0.8
    }
  },
  props => props.css,
  display,
  space,
  width,
  color,
  fontSize,
  fontWeight,
  lineHeight
)

export const ExternalLink = ({ to, ...props }) => (
  <div>
    <ExternalBaseLink href={to} {...props} />
  </div>
)
