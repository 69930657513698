import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import { Box, Flex } from '../Primitives'

export const animateHeight = keyframes`
  0% {
    transform: translate3d(0, -10px, 0);
  }
  40% {
    transform: translate3d(0, 5px, 0);
  }
  60%, 100% {
    transform: translate3d(0, 0, 0);
  }
`

export const animateAlpha = keyframes`
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  40%, 100% {
    opacity: 0.5;
    transform: translate3d(0, 5px, 0);
  }
`

const Base = styled(({ animationDelay = 0, ...rest }) => <Box {...rest} />)`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px 5px;
  margin: 10px 2px;
  background-color: #555;
`

const Finger = styled(Base)`
  animation: ${animateAlpha} 0.8s ease-out ${(props) => props.animationDelay}s alternate infinite;
`

Finger.propTypes = {
  animationDelay: PropTypes.number,
}

/**
 * LoadingIcon component.
 *
 * This component renders a loading animation icon.
 *
 * @component
 * @example
 * // Usage example:
 * <LoadingIcon />
 *
 * @returns {JSX.Element} The LoadingIcon component.
 */
export const LoadingIcon = (props) => (
  <Flex mt={4} mx={3} {...props}>
    <Finger />
    <Finger animationDelay={0.15} />
    <Finger animationDelay={0.3} />
    <Finger animationDelay={0.45} />
  </Flex>
)
