import styled from 'styled-components'
import { space, layout, typography, color, flexbox, grid, position, system } from 'styled-system'
import shouldForwardProp from '@styled-system/should-forward-prop'

/**
 * Rebass primitives with additional styled-system props for ease-of-use
 * These are essentially the same components, but with additional theme
 * configuration from rebass
 *
 * We need to add these back, because either styled, or styled().withConfig() strips
 * styled-system functions.
 */
export const Box = styled('div').withConfig({ shouldForwardProp })(
  (props) => props.css,
  space,
  layout,
  typography,
  color,
  flexbox,
)

const gap = system({
  gap: {
    property: 'gap',
    scale: 'space',
  },
})

export const Flex = styled('div').withConfig({ shouldForwardProp })(
  {
    display: 'flex',
  },
  (props) => props.css,
  space,
  layout,
  color,
  flexbox,
  grid,
  position,
  gap,
  typography,
)

export const Grid = styled('div')
  .attrs(({ placeItems, ...props }) => ({
    placeItems: placeItems || 'center',
    ...props,
  }))
  .withConfig({
    shouldForwardProp,
  })(
  {
    display: 'grid',
  },
  (props) => props.css,
  space,
  layout,
  color,
  grid,
  position,
  flexbox, // We have placeItems, but this is more for alignItems and justifyContent
)

export const Text = styled('span').withConfig({ shouldForwardProp })(
  (props) => props.css,
  space,
  layout,
  typography,
  color,
  flexbox,
)
